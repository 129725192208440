.page.carousel-2-page {
  background: linear-gradient(to right, #ffedd5, #fcfcfc);
  font-size: 14px;
  color: #010101;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  width: 30%;
  height: 250px;
  filter: saturate(1.2);
  -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0006);
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  gap: 15%;
  align-items: center;
}

.carousel-title {
  color: #2c3e50;
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  padding-bottom: 10px;
  text-align: center;
}

.carousel-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: #e74c3c;
}

.carousel-instruction {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  display: block;
}

p, .add-photo-container {
  font-size: 1.2rem;
  color: #2c3e50;
  text-align: center;
  width: 80%;
}

/* Media Queries */
@media (max-width: 1024px) {
  .swiper .swiper-slide {
    width: 50%;
    height: 220px;
  }
  
  .carousel-title {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .swiper .swiper-slide {
    width: 60%;
    height: 200px;
  }

  .carousel-title {
    font-size: 1.8rem;
  }

  p, .add-photo-container {
    font-size: 1rem;
    width: 90%;
  }

  .carousel-instruction {
    display: block;
  }
}

@media (max-width: 480px) {
  .page.carousel-2-page {
    height: auto;
    padding: 20px 0;
  }
  
  .swiper {
    padding: 20px 0;
  }
  
  .swiper .swiper-slide {
    width: 70%;
    height: 180px;
  }

  .carousel-title {
    font-size: 1.5rem;
    padding-bottom: 5px;
  }
  
  .carousel-title::after {
    width: 50px;
    height: 2px;
  }

  p, .add-photo-container {
    font-size: 0.9rem;
    width: 95%;
  }
}

@media (max-width: 360px) {
  .swiper .swiper-slide {
    width: 80%;
    height: 150px;
  }
  
  .carousel-title {
    font-size: 1.3rem;
  }
}