/* FullscreenVideo.css */
.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    overflow: hidden;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container.active {
    opacity: 1;
    z-index: 1;
}

.video-container.hidden {
    opacity: 0;
    z-index: 0;
}

.fullscreen-video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Aseguramos que el video horizontal use el ancho completo */
.video-container.horizontal .fullscreen-video {
    width: 100%;
    height: auto;
}

/* Aseguramos que el video vertical use la altura completa */
.video-container.vertical .fullscreen-video {
    height: 100%;
    width: auto;
}