.btn3Wrapper {
  width: 125px;
  position: relative;
  display: flex;
  justify-content: center;
}

.btn3 {
  position: relative;
  width: 125px;
  height: 60px;
  border-radius: 30px;
  border: none;
  padding: 5px 10px;
  background-color: #ff7b00;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  cursor: pointer;
  transition: 0.3s linear;
  
}

.btn3 .mysvg {
  display: none;
}

.btn3Wrapper:hover .btn3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.3s linear;
}

.btn3Wrapper:hover .texto {
  display: none;
}

.btn3Wrapper:hover .mysvg {
  display: inline;
}

.btn3Wrapper:hover .btn3::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 3.5px solid transparent;
  border-top: 3.5px solid #fff;
  border-right: 3.5px solid #fff;
  border-radius: 50%;
  animation: animateC 2.5s linear infinite;
}

@keyframes animateC {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}
